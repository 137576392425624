/** @format */

import axios from "./axios.js";
import Qs from "qs";
// import { Dialog } from 'vant';
import { ElMessage } from "element-plus";
import { test, formal } from "@/config/index";
import { ElMessageBox } from 'element-plus';

var base_url = "http://119.45.235.140:3196";
var powerbi = "//app.powerbi.cn/";
var aie = "//192.168.0.111:8081/";
var file_load = "http://43.137.16.233:3196"

var write_online = config_write || "http://43.137.16.233:1310"
// var write_online = "https://cic.ln.cn"
var wansi_url = "https://vcase.pub"
var vcase_url = "http://49.232.135.204:8666"
var rag = "https://appbuilder.baidu.com/s/"
var qianfan = "https://qianfan.baidubce.com/"
if (test) {
    console.log("开发环境");

    // base_url = config_url || "http://192.168.1.125:9000"; //杨旭
    vcase_url = "http://192.168.1.125:17389"
    // base_url = config_url || "http://192.168.1.72:8081"; //72
    base_url = "http://119.45.235.140:3191"; //线上测试服务器
    // base_url = "http://192.168.1.146:1386"; //
    aie = "//192.168.1.117:8081/";
    // aie = "//119.45.235.140:4120/";
    file_load = "http://43.137.16.233:3196";
    // file_load = "http://192.168.0.109:9000"; //杨旭
} else {
    // console.log("生产环境");
    base_url = config_url || "http://49.235.231.224:8888";
    aie = "//119.45.235.140:4120/";
    vcase_url = config_vcase || "http://49.232.135.204:8666"
    file_load = "http://43.137.16.233:3196";
    write_online = config_write || "http://192.168.1.47:9003"
}

var url = {
    htmltest: base_url + "/Api/V1/Base_Test/UpdateTest",
    upTest: qianfan + "v2/app/conversation/file/upload",

    // 基础功能
    echarts: "/static/echarts/index.html?x=" + Math.random(),
    reg: base_url + "/Api/V1/Table_User/AddUserInfo/", //注册
    login: base_url + "/Api/V1/Login/Login/", //登录 //LoginRefClassCount Login
    Select_Schools: base_url + "/Api/V1/Common/GetSchoolList/", //学校数据
    reuser: base_url + "/Api/V1/Table_User/UpdateUserInfo/", //修改个人信息
    repwd: base_url + "/Api/V1/Table_User/UpdateUserPwd/", //修改密码
    GetPbiMenuList: base_url + "/Api/V1/M_PbiMenu/GetPbiMenuList", //
    CheckUserTel: base_url + "/Api/V1/Login/CheckUserTel", //
    // 忘记密码
    CheckUser: base_url + "/Api/V1/Common/CheckUser", //第一步
    SendEmail: base_url + "/Api/V1/Common/SendEmail", //发送邮件
    UpdatePwdByEmail: base_url + "/Api/V1/Table_User/UpdatePwdByEmail", //第二步
    SendSms: base_url + "/Api/V1/Log_SMS/SendSms",
    // 会员中心
    GetUserM_LicenseDays: base_url + "/Api/V1/D_UserLicenseType/GetUserM_LicenseDays",
    GetLicenseTypeList: base_url + "/Api/V1/M_LicenseType/GetLicenseTypeList",
    AddUserPayData: base_url + "/Api/V1/D_UserPayData/AddUserPayData",
    GetUserPayResult: base_url + "/Api/V1/D_UserPayData/GetUserPayResult",
    ActivationUserLicense: base_url + "/Api/V1/D_VDCUsageStatistics/ActivationUserLicense",

    VcaseLogin: base_url + "/Api/V1/Table_User/VcaseLogin", //
    HomeTotalData: base_url + "/Api/V1/Common/HomeTotalData",//数据统计
    HomeTotalDataWeek: base_url + "/Api/V1/Common/HomeTotalDataWeek",//周数据统计


    // 工具
    CheckPerTool: base_url + "/Api/V1/D_PerToolData/CheckPerTool", //
    GetToolMenu: base_url + "/Api/V1/D_PerToolData/GetToolMenu", //
    GetPagesPerToolData: base_url + "/Api/V1/D_PerToolData/GetPagesPerToolData",// 使用过的工具

    // 权限验证
    CheckPerSearch: base_url + "/Api/V1/D_PerToolData/CheckPerSearch",// 检索功能检索前的权限验证
    CheckPerAIG: base_url + "/Api/V1/D_PerToolData/CheckPerAIG", //AIG权限验证
    CheckPerPaperSelect: base_url + "/Api/V1/D_PerToolData/CheckPerPaperSelect", //论文选题权限验证
    CheckPerRAG: base_url + "/Api/V1/D_PerToolData/CheckPerRAG", //RAG权限验证


    // 智能文本检索
    GetCompanyCodeList: wansi_url + "/Api/V1/M_CategoryData/GetCompanyCodeList",
    GetHomeDataList: wansi_url + "/Api/V1/GetCategoryDataController/GetHomeDataList",
    GetTxtDataList: base_url + "/Api/V1/HomePage/GetHomeDataList",
    PerformanceDataExportport: wansi_url + "/Api/V1/Common/PerformanceDataExportport",
    get_reference_group: wansi_url + "/Api/V1/GetCaseModelController/get_reference_group",
    get_experimental_group: wansi_url + "/Api/V1/GetCaseModelController/get_experimental_group",
    search_by_attribute: wansi_url + "/Api/V1/GetCaseModelController/search_by_attribute",
    search_by_condition: wansi_url + "/Api/V1/GetCaseModelController/search_by_condition",

    // 案例云
    GetCaseQuotaTypeList: wansi_url + "/Api/V1/M_CaseQuotaType/GetCaseQuotaTypeList", //获取项目类别
    intelligent_GetCategoryList:wansi_url + "/Api/V1/M_CaseQuotaType/GetCategoryList",
    intelligent_GetCategoryDetail:wansi_url + "/Api/V1/M_CaseQuotaType/GetCategoryDetail",

    GetCaseQuotaTypeCofigList:wansi_url + "/Api/V1/M_CaseQuotaType/GetCaseQuotaTypeCofigList",// 方案预设
    GetCaseQuotaTypeCofigWhereList:wansi_url + "/Api/V1/M_CaseQuotaType/GetCaseQuotaTypeCofigWhereList",
    DetailDataForCase:wansi_url + "/Api/V1/GetCategoryDataController/GetCompanyDetailDataForCase",

    GetCategoryDetail: wansi_url + "/Api/V1/GetCategoryDataController/GetCategoryDetail",
    GetTenYearDataList: wansi_url + "/Api/V1/GetCategoryDataController/GetTenYearDataList",
    GetThoseYearsDataList: wansi_url + "/Api/V1/GetCategoryDataController/GetThoseYearsDataList",

    GetCaseReportList:base_url + "/Api/V1/D_AIQuestion/GetCaseReportList", // 我的案例列表
    GetAIQuestion:base_url + "/Api/V1/D_AIQuestion/GetAIQuestion", // 预设问题获取
    AddCaseForReport:base_url + "/Api/V1/D_AIQuestion/AddCaseForReport", // 生成案例
    AddCaseForReportAIQuestion:base_url + "/Api/V1/D_AIQuestion/AddCaseForReportAIQuestion", // 生成案例
    DelCaseReport:base_url + "/Api/V1/D_AIQuestion/DelCaseReport", // 删除案例
    UpdateCaseReport:base_url + "/Api/V1/D_AIQuestion/UpdateCaseReport", // 修改案例

    GetPagesSchoolReport:base_url + "/Api/V1/D_AIQuestion/GetPagesSchoolReport", // 校内报告获取
    GetPagesSchoolReportDetail:base_url + "/Api/V1/D_AIQuestion/GetReportDetailQuestionMaster", // 校内报告详情


    // 公益课程
    

    
    // vdcCourse
    GetPagesClassMaster: base_url + "/Api/V1/M_ClassMaster/GetPagesClassMaster", // 标准课程列表

    SaveFile: base_url + "/Api/V1/Base_RelationFile/SaveFile/",
    UploadTest: file_load + "/Api/V1/Base_RelationFile/UploadTest/", // 样本中上传图片
    GetUserInfo: base_url + "/Api/V1/Table_User/GetUserInfo/",

    GetReport_r: base_url + "/Api/V1/Table_Report!!/GetReport!!/",
    AddReport_r: base_url + "/Api/V1/Table_Report!!/AddReport!!/",
    UpdateReport_r: base_url + "/Api/V1/Table_Report!!/UpdateReport!!/",
    SubmitReport_r: base_url + "/Api/V1/Table_Report!!/SubmitReport!!/",
    GetReportVase_r: base_url + "/Api/V1/Table_Report!!/GetReportVase/",
    // 财务管理、会计大数据课程 S
    GetReport_fa: base_url + "/Api/V1/Table_standard/Getstandard/",
    AddReport_fa: base_url + "/Api/V1/Table_standard/Addstandard/",
    UpdateReport_fa: base_url + "/Api/V1/Table_standard/Updatestandard/",
    SubmitReport_fa: base_url + "/Api/V1/Table_standard/Submitstandard/",
    GetReportVase_fa: base_url + "/Api/V1/Table_standard/GetReportVase/",
    Getpolicy: base_url + "/Api/V1/Table_standard/Getpolicy", // 获取会计政策 附件5投资性房地产会计政策,5长期股权投资会计政策
    GetFactbase: base_url + "/Api/V1/Table_standard/GetFactbase", //获取事实库 附件会计政策变更事实库,会计差错更正事实库
    GetCode: base_url + "/Api/V1/Table_standard/GetCode", //获取公司列表
    Getecharchfoldline: base_url + "/Api/V1/Table_standard/Getecharchfoldline", //获取echarch折线图
    // 财务管理、会计大数据课程 E
    GetStudentAnalysis: base_url + "/Api/V1/Table_StudentList/GetReport/",

    GetTaskList: base_url + "/Api/V1/AtandardClassModel/GetAtandardClassModel/",
    AddClassList: base_url + "/Api/V1/Table_ClassList/AddClassList/",
    getTeacherClass: base_url + "/Api/V1/Table_ClassList/GetClassList/", //教师 我的课程
    getStudentClass: base_url + "/Api/V1/Table_StudentList/GetStudentList", //学生 我的课程
    getTeacherTask: base_url + "/Api/V1/Table_TaskList/GetTaskList/",
    GetTaskMasterList: base_url + "/Api/V1/Table_TaskList/GetTaskMasterList/",
    updateClassList: base_url + "/Api/V1/Table_ClassList/UpdateClassList/",
    DeleteClassList: base_url + "/Api/V1/Table_ClassList/DeleteClassList/",
    UpdateTaskList: base_url + "/Api/V1/Table_TaskList/UpdateTaskList/",
    DeleteTaskList: base_url + "/Api/V1/Table_TaskList/DeleteTaskList/",
    UpdateTaskListAll: base_url + "/Api/V1/Table_TaskList/UpdateTaskListAll/",
    UploadFile: file_load + "/Api/V1/Table_ReportSupportingDocumentation/UploadFile/",
    UploadTaskAttachmentFile: file_load + "/Api/V1/Table_TaskList/UploadTaskAttachmentFile/", //教师端上传任务附件
    UpdateTaskListAttachment: base_url + "/Api/V1/Table_TaskList/UpdateTaskListAttachment/", //教师端上传任务附件
    DownloadFile: base_url + "/Api/V1/Table_ReportSupportingDocumentation/DownloadFile/",
    GetStudentListForManage: base_url + "/Api/V1/Table_StudentList/GetStudentListForManage/", //课程管理
    WordToPDF: base_url + "/Api/V1/Common/WordToPDF",
    WordToPDF: base_url + "/Api/V1/Common/WordToPDF",
    // cf05c89a697205becc46a9bef1fdb0e2b1330820
    CategoryList: base_url + "/Api/V1/Common/CategoryList/",
    GetReport: base_url + "/Api/V1/Table_Report/GetReport/",
    AddReport: base_url + "/Api/V1/Table_Report/AddReport/",
    UpdateReport: base_url + "/Api/V1/Table_Report/UpdateReport/",
    DeleteReport: base_url + "/Api/V1/Table_Report/DeleteReport/",
    CategoryList: base_url + "/Api/V1/Common/CategoryList/",
    GetReportSupportingDocumentation: base_url + "/Api/V1/Table_ReportSupportingDocumentation/GetReportSupportingDocumentation/",
    AddReportSupportingDocumentation: base_url + "/Api/V1/Table_ReportSupportingDocumentation/AddReportSupportingDocumentation/",
    UpdateReportSupportingDocumentation: base_url + "/Api/V1/Table_ReportSupportingDocumentation/UpdateReportSupportingDocumentation/",
    DeleteReportSupportingDocumentation: base_url + "/Api/V1/Table_ReportSupportingDocumentation/DeleteReportSupportingDocumentation/",
    GetClassListByPollCode: base_url + "/Api/V1/Table_ClassList/GetClassListByPollCode", //加入班级 注册码查询
    getPollCompany: base_url + "/Api/V1/上市公司属性表/Get上市公司属性表", //加入班级 获取公司列表
    getPollCompany_view: base_url + "/Api/V1/上市公司属性表/Get上市公司属性表_可视化", //学生管理 修改分析对象
    AddStudentList: base_url + "/Api/V1/Table_StudentList/AddStudentList", //加入班级
    GetMainTable: base_url + "/Api/V1/主表项目_筛选/Get主表项目_筛选", //学生端 分析对象 主表数据
    GetMainTable_view: base_url + "/Api/V1/主表项目_筛选/Get主表项目_筛选可视化", //学生端 分析对象 主表数据
    GetStockTable: base_url + "/Api/V1/主表项目_筛选/Get存货_筛选", //学生端 分析对象 存货
    GetStockTable_view: base_url + "/Api/V1/主表项目_筛选/Get存货_筛选可视化", //学生端 分析对象 存货
    GetInTable: base_url + "/Api/V1/主表项目_筛选/Get行业战略_筛选", //学生端 分析对象 行业战略
    GetInTable_view: base_url + "/Api/V1/主表项目_筛选/Get行业战略_筛选可视化", //学生端 分析对象 行业战略
    tableDownLoad: file_load + "/Api/V1/主表项目_筛选/筛选Export", //学生端 导出表
    tableDownLoad_view: file_load + "/Api/V1/主表项目_筛选/筛选Export_可视化", //学生端 导出表
    UpdateManageTask: base_url + "/Api/V1/Table_TaskList/UpdateManageTask", //重置任务
    DeleteStudentList: base_url + "/Api/V1/Table_StudentList/DeleteStudentList", //移除学生
    selectCPY: base_url + "/Api/V1/上市公司属性表/GetCompanyList", //创建课程 查询公司列表
    AddUpdateOnlyGuidstuAnalysisObj: base_url + "/Api/V1/Table_StudentList/UpdateStudentListOnlyGuidstuAnalysisObj", //更改学生列表
    UpdateTaskListAllDeadline: base_url + "/Api/V1/Table_TaskList/UpdateTaskListAllDeadline", //一键修改截止日期
    // cf05c89a697205becc46a9bef1fdb0e2b1330820

    insertrecord: base_url + "/insertrecord/", // PBI
    GetReportList: base_url + "/Api/V1/Table_Report/GetReportList/",
    GetReportCollectionList: base_url + "/Api/V1/Table_Report/GetReportCollectionList/",
    GetSchoolReport: base_url + "/Api/V1/Table_Report/GetSchoolReport/",
    CollectionReport: base_url + "/Api/V1/Table_Report/CollectionReport/",
    GiveReport: base_url + "/Api/V1/Table_Report/GiveReport/",
    GetReportDiscussList: base_url + "/Api/V1/Table_ReportDiscuss/GetReportDiscussList/",
    AddReportDiscuss: base_url + "/Api/V1/Table_ReportDiscuss/AddReportDiscuss/",
    AddReportDiscussReply: base_url + "/Api/V1/Table_ReportDiscussReply/AddReportDiscussReply/",
    DeleteReportDiscuss: base_url + "/Api/V1/Table_ReportDiscuss/DeleteReportDiscuss/",
    DeleteReportDiscussReply: base_url + "/Api/V1/Table_ReportDiscussReply/DeleteReportDiscussReply/",
    GetDiscussReplayList: base_url + "/Api/V1/Table_ReportDiscuss/GetDiscussReplayList/",

    GetStudentScore: base_url + "/Api/V1/Common/GetStudentScore/", //考核初始显示数据
    UpdateStudentGrade: base_url + "/Api/V1/Table_ClassList/UpdateStudentGrade", // 评级
    StudentScoreExport: file_load + "/Api/V1/Common/StudentScoreExport", //成绩导出
    DownWordOrPDF: file_load + "/Api/V1/Common/DownWordOrPDF", //样本导出
    StudentUploadFile: file_load + "/Api/V1/Common/GetClassStudentUploadFile",//上传文件导出
    DownFile: base_url + "/Api/V1/Common/DownFile", //数智课程 考核 工具导出
    GetSupportingDocuments: base_url + "/Api/V1/Common/GetSupportingDocuments", //获取报告右侧链接
    MyUploadFile: base_url + "/Api/V1/Table_TaskList/MyUploadFile/", //学生 我的上传
    AddUpdateReportMyUpload: base_url + "/Api/V1/Table_TaskList/AddUpdateReportMyUpload", //学生 我的上传
    AddUpdatePwd: base_url + "/Api/V1/Table_TaskList/AddUpdatePwd", //重置密码
    ClassCount: base_url + "/Api/V1/Login/ClassCount",
    HClassCount: vcase_url + "/Api/Class/ClassCount",
    UndoTaskRelease: base_url + "/Api/V1/Table_TaskList/UndoTaskRelease", //撤销单个任务发布
    UndoTaskReleaseAll: base_url + "/Api/V1/Table_TaskList/UndoTaskReleaseAll", //撤销选中行任务发布
    UpdateManageTaskALL: base_url + "/Api/V1/Table_TaskList/UpdateManageTaskALL", //学生管理 一键重置任务
    GetClassStudentScoreData: file_load + "/Api/V1/Common/GetClassStudentScoreData", //学情分析
    GetPbiLink: base_url + "/Api/V1/Table_PbiLink/GetPbiLink", //学情分析
    GetClassStudentScoreDataSubmit: base_url + "/Api/V1/Common/GetClassStudentScoreDataSubmit", //学情分析
    StudentDownWord: file_load + "/Api/V1/Common/StudentDownWord",//学生端下载样本报告

    GetcourseIndex: base_url + "/Api/V1/AtandardClassModel/GetcourseIndex", //
    GetUpdate: base_url + "/Api/V1/Table_User/GetUpdate", //
    GetReportSchoolList: base_url + "/Api/V1/Common/GetReportSchoolList", //
    CreateDoc: file_load + "/Api/V1/Common/CreateDoc", //

    AddClassStudentDiscuss: base_url + "/Api/V1/Table_ClassStudentDiscuss/AddClassStudentDiscuss",//发布留言
    GetStudentDiscuss: base_url + "/Api/V1/Table_ClassStudentDiscuss/GetStudentDiscuss",//获取留言
    UpdataIsRead: base_url + "/Api/V1/Table_ClassStudentDiscuss/UpdataIsRead", //已读
    UploadImg: base_url + "/Api/V1/Table_ClassStudentDiscuss/UploadTest", //留言上传图片
    UploadAnnouncement: base_url + "/Api/V1/Table_Announcement/UploadTest", //常见问题上传图片
    AddAnnouncement: base_url + "/Api/V1/Table_Announcement/AddAnnouncement", //常见问题发布
    GetAnnouncement: base_url + "/Api/V1/Table_Announcement/GetAnnouncement", //常见问题查询
    UpdateAnnouncement: base_url + "/Api/V1/Table_Announcement/UpdateAnnouncement", //常见问题编辑
    DeleteAnnouncement: base_url + "/Api/V1/Table_Announcement/DeleteAnnouncement", //常见问题删除
    getMesClass: base_url + "/Api/V1/Table_ClassStudentDiscuss/GetClassList", //留言查询班级
    getMesTask: base_url + "/Api/V1/Table_ClassStudentDiscuss/GetTaskList", //留言查询任务
    UpdataIsReadsettled: base_url + "/Api/V1/Table_ClassStudentDiscuss/UpdataIsReadsettled", //已解决
    GetMessage: base_url + "/Api/V1/Table_ClassStudentDiscuss/GetMessage", //任务未读提示
    GetReportQuestionMaster: base_url + "/Api/V1/Table_ReportQuestionDate/GetReportQuestionMaster", //获取教学样本的问题

    AddReportQuestionDate: base_url + "/Api/V1/Table_ReportQuestionDate/AddReportQuestionDate", //问杨旭
    GetReportQuestionDate: base_url + "/Api/V1/Table_ReportQuestionDate/GetReportQuestionDate", //问杨旭
    GetReportDetailQuestionMaster: base_url + "/Api/V1/Table_ReportQuestionDate/GetReportDetailQuestionMaster",

    StudentToolsDel: base_url + "/Api/V1/Table_StudentList/StudentToolsDel",//大数据财务管理 重置工具

    // 知识点
    GetClassTaskList: base_url + "/Api/V1/Table_ClassList/GetClassTaskList", //获取班级下已发布任务名称列表
    GetStudentKnowledgePoints: base_url + "/Api/V1/Table_ClassList/GetStudentKnowledgePoints", //获取指定任务的知识点统计信息
    GetStudentTaskList: base_url + "/Api/V1/Common/GetStudentTaskList/",
    GetKnowledgePointsDown: base_url + "/Api/V1/Table_ClassList/GetKnowledgePointsDown ", //知识点下载

    AddthesisDdta: base_url + "/Api/V1/Table_thesisDdta/AddthesisDdta", //论文小助手统计
    GetReportviewTop10List: base_url + "/Api/V1/Reportreport/GetReportviewTop10List", //浏览量前10列表
    GetReportDissTop10List: base_url + "/Api/V1/Reportreport/GetReportDissTop10List", //评论量前10列表
    GetReportviewList: base_url + "/Api/V1/Reportreport/GetReportviewList", //浏览量汇总
    GetReportDissList: base_url + "/Api/V1/Reportreport/GetReportDissList", //评论量汇总
    GetReporGiveList: base_url + "/Api/V1/Reportreport/GetReporGiveList", //点赞量汇总
    
    




    // vcaseCourse
    GetPbiLinkList: base_url + "/Api/V1/Table_PbiLink/GetPbiLinkList",
    CreateClass: vcase_url + "/Api/Class/CreateClass", //新建班级
    GetAllClassesByUserId: vcase_url + "/Api/Class/GetAllClassesByUserId", //获取教师班级信息
    GetStuClass: vcase_url + "/Api/Class/GetStuClass", //获取学生班级信息
    // EditClass: vcase_url + "/Api/Class/EditClass", //班级维护
    EditClassWithSeal: vcase_url + "/Api/Class/EditClassWithSeal", //班级维护
    DeleteClass: vcase_url + "/Api/Class/DeleteClass", //班级删除
    GetCourseTasks: vcase_url + "/Api/Class/GetCourseTasks", //获取标准课程任务信息
    GetClassTask: vcase_url + "/Api/ClassTask/GetClassTask", //获取班级任务信息
    Authority: vcase_url + "/Api/Class/Authority", //获取开课权限
    FindClass: vcase_url + "/Api/Class/FindClass", //注册码查询
    JoinClass: vcase_url + "/Api/Class/JoinClass", //加入课程
    GetOneClassTask: vcase_url + "/Api/ClassTask/GetOneClassTask", //修改单个任务时提供原数据
    EditClassTask: vcase_url + "/Api/ClassTask/EditClassTask", //任务维护
    GetStuTask: vcase_url + "/Api/StuTask/GetStuTask", //获取学生任务信息
    GetStuList: vcase_url + "/Api/Class/GetStuList", //获取学生信息
    RemoveStuFromClass: vcase_url + "/Api/Class/RemoveStuFromClass", //移除学生
    BatchModify: vcase_url + "/Api/ClassTask/BatchModify", //一键维护
    BatchPublishing: vcase_url + "/Api/ClassTask/BatchPublishing", //一键发布
    GetAnswerRecord: vcase_url + "/Api/AnswerRecord/GetAnswerRecord", //学生打开报告
    GetOneAnswer: vcase_url + "/Api/AnswerRecord/GetOneAnswer", //教师打开报告
    SaveAnswerRecord: vcase_url + "/Api/AnswerRecord/SaveAnswerRecord", //案例报告保存
    SubmitAnswerRecord: vcase_url + "/Api/AnswerRecord/SubmitAnswerRecord", //案例报告提交
    ResetTask: vcase_url + "/Api/AnswerRecord/ResetTask", //重置任务
    ResetTaskByStu: vcase_url + "/Api/AnswerRecord/ResetTaskByStu", //重置最后一个任务
    upstureportpath: vcase_url + "/Api/AnswerRecord/upstureportpath", //重置工具
    GetVcTaskList: vcase_url + "/Api/Class/GetTaskList", //获取重置任务的任务列表
    GetStufile: vcase_url + "/Api/Class/GetStufile", //考核 文件导出
    getstureportfiles: vcase_url + "/Api/Class/getstureportfiles", //考核 样本导出
    exportsturepord: vcase_url + "/Api/AnswerRecord/exportsturepord", //学生样本导出
    // 记账凭证
    GetBusinessListByCourseTaskName: vcase_url + "/Api/Voucher/GetBusinessListByCourseTaskName", //获取业务列表
    GetVoucherByCourseTaskName: vcase_url + "/Api/Voucher/GetVoucherByCourseTaskName", //进入任务获取凭证信息
    GetVoucher: vcase_url + "/Api/Voucher/GetVoucher", //根据业务获取凭证信息
    VoucherReview: vcase_url + "/Api/Voucher/VoucherReview", //凭证审核
    Accounting: vcase_url + "/Api/Voucher/Accounting", //记账
    GetAccountingItem: vcase_url + "/Api/Voucher/GetAccountingItem", //会计科目
    VCGetClassStudentScoreData: vcase_url + "/Api/Class/GetClassStudentScoreData", //学情分析
    Getsubmitandnosubmit: vcase_url + "/Api/Class/Getsubmitandnosubmit", //学情分析未提交信息查看
    vcGetStudentScore: vcase_url + "/Api/Class/GetStudentScore", //考核
    EditVoucher: vcase_url + "/Api/Voucher/EditVoucher", //财务会计凭证保存
    EditVoucher_CBKJ: vcase_url + "/Api/Voucher/EditVoucher_CBKJ", //成本会计凭证保存
    Examine_CBKJ: vcase_url + "/Api/Voucher/Examine_CBKJ", //成本会计考核
    Examine_CWKJ: vcase_url + "/Api/Voucher/Examine_CWKJ", //财务会计考核
    EditVoucher_KJZH: vcase_url + "/Api/Voucher/EditVoucher_KJZH", //会计综合凭证保存
    GetVoucherListByChengben: vcase_url + "/Api/Voucher/GetVoucherListByCourseTaskName_CBKJ", //成本会计进入任务获取凭证信息
    GetVoucherList_CBKJ: vcase_url + "/Api/Voucher/GetVoucherList_CBKJ", //成本会计根据业务获取凭证信息
    downfilefor_CB_JC: vcase_url + "/Api/Class/downfilefor_CB_JC", //工具下载
    // 沙盘
    GetYW: vcase_url + "/Api/JCKJ/GetYW", //获取当前业务
    NextYW: vcase_url + "/Api/JCKJ/NextYW", //下一业务
    LastYW: vcase_url + "/Api/JCKJ/LastYW", //上一业务
    // 基础会计
    GetJckjVoucher: vcase_url + "/Api/JCKJ/GetVoucherList", //根据业务获取凭证信息
    GetRecord: vcase_url + "/Api/Examination/GetRecord", //基础会计 考试
    CreateVoucher: vcase_url + "/Api/JCKJ/CreateVoucher", //凭证
    SaveVoucher: vcase_url + "/Api/JCKJ/SaveVoucher", //凭证保存
    DeleteCredential: vcase_url + "/Api/Voucher/DeleteCredential", //凭证删除
    SaveDocuments: vcase_url + "/Api/Examination/SaveDocuments", 
    GetOriginalVoucheList: vcase_url + "/Api/JCKJ/GetOriginalVoucheList", //选择凭证
    GetDocuments: vcase_url + "/Api/Examination/GetDocuments", 
    GetYWOriginalVoucheList: vcase_url + "/Api/JCKJ/GetYWOriginalVoucheList", //点击UD查看当前UD原始单据
    AccountSummary: vcase_url + "/Api/JCKJ/AccountSummary", //科目汇总表
    GetSummary: vcase_url + "/Api/Examination/GetSummary", 
    SubmitDocuments: vcase_url + "/Api/Examination/SubmitDocuments", //考试 提交
    Examine: vcase_url + "/Api/JCKJ/Examine", //考核
    GetScoreList: vcase_url + "/Api/Examination/GetScoreList", 
    // 会计综合
    KJZHVoucher: vcase_url + "/Api/AnswerRecord/Voucher", //选择单据

    
    // caseCategory
    // chat ai
    GetAIMenu:wansi_url + "/Api/V1/D_AIQuestion/GetAIMenu",
    GetAIQuestion:wansi_url + "/Api/V1/D_AIQuestion/GetAIQuestion", // 预设问题获取
    GetAIQuestionDemo:wansi_url + "/Api/V1/D_AIQuestion/GetAIQuestionDemo",
    UpdateAIQuestion:wansi_url + "/Api/V1/D_AIQuestion/UpdateAIQuestion",

    GetM_Category_L: wansi_url + "/Api/V1/GetCategoryDataController/GetM_Category_L",
    GetPaperList: wansi_url + "/Api/V1/GetCategoryDataController/GetPaperList", //开题报告
    GetCKLsit: wansi_url + "/Api/V1/D_CK_Thesis/GetCKLsit",
    GetresearchpathList: wansi_url + "/Api/V1/D_researchpath/GetresearchpathList",
    AddMethodView: wansi_url + "/Api/V1/M_MethodType/AddMethodView",
    GetLiteratureList: wansi_url + "/Api/V1/GetCategoryDataController/GetLiteratureList",
    GetTopicEvaluation: wansi_url + "/Api/V1/M_CategoryData/GetTopicEvaluation",
    GetMethodDocumentByCategory: wansi_url + "/Api/V1/M_CategoryData/GetMethodDocumentByCategory",
    GetMethodWordCloudData: wansi_url + "/Api/V1/M_CategoryData/GetMethodWordCloudData",
    GetQuestionWordCloudData: wansi_url + "/Api/V1/M_CategoryData/GetQuestionWordCloudData",
    GetCategoryData: wansi_url + "/Api/V1/M_CategoryData/GetCategoryData",
    NumericalStatisticsDataExport: wansi_url + "/Api/V1/Common/NumericalStatisticsDataExport",
    DescriptiveStatisticsOfFeaturesDataExportport: wansi_url + "/Api/V1/Common/DescriptiveStatisticsOfFeaturesDataExportport",
    GetNumericalStatisticsData: wansi_url + "/Api/V1/GetCategoryDataController/GetNumericalStatisticsData",
    GetDescriptiveStatisticsOfFeaturesData_Area: wansi_url + "/Api/V1/GetCategoryDataController/GetDescriptiveStatisticsOfFeaturesData_Area",
    GetDescriptiveStatisticsOfFeaturesData_Industry: wansi_url + "/Api/V1/GetCategoryDataController/GetDescriptiveStatisticsOfFeaturesData_Industry",
    GetDescriptiveStatisticsOfFeaturesData_PropertyNature: wansi_url + "/Api/V1/GetCategoryDataController/GetDescriptiveStatisticsOfFeaturesData_PropertyNature",
    GetDescriptiveStatisticsOfFeaturesData_ListingSector: wansi_url + "/Api/V1/GetCategoryDataController/GetDescriptiveStatisticsOfFeaturesData_ListingSector",
    GetEventTimeSeries: wansi_url + "/Api/V1/GetCategoryDataController/GetEventTimeSeries",

    GetCategoryList: wansi_url + "/Api/V1/GetCategoryDataController/GetCategoryList",
    GetCategory: wansi_url + "/Api/V1/M_Category_L/GetCategory",
    GetIndustryList: wansi_url + "/Api/V1/GetCategoryDataController/GetIndustryList",
    GetProvinceList: wansi_url + "/Api/V1/GetCategoryDataController/GetProvinceList",
    GetProvinceList: wansi_url + "/Api/V1/GetCategoryDataController/GetProvinceList",
    GetM_CategoryData: wansi_url + "/Api/V1/GetCategoryDataController/GetM_CategoryData",

    Common_GetCategoryList: wansi_url + "/Api/V1/Common/GetCategoryList",

    GetTheoreticalMethodName: wansi_url + "/Api/V1/GetCategoryDataController/GetTheoreticalMethodName",
    GetMethodType: wansi_url + "/Api/V1/M_MethodType/GetMethodType",
    GetCategoryDataList: wansi_url + "/Api/V1/M_QusetionType/GetCategoryDataList", //问题优先类别选择
    GetCategoryAll: wansi_url + "/Api/V1/M_CategoryData/GetCategoryAll",
    GetQuestionWordCloudDataByName: wansi_url + "/Api/V1/M_CategoryData/GetQuestionWordCloudDataByName",
    GetSelectQusetionTypeList: wansi_url + "/Api/V1/M_QusetionType/GetSelectQusetionTypeList", //问题优先类别选择
    GetMethodWordByCategoryName: wansi_url + "/Api/V1/M_CategoryData/GetMethodWordByCategoryName",
    GetCustomSetting: wansi_url + "/Api/V1/M_CategoryData/GetCustomSetting",
    CardInformationByCase: wansi_url + "/Api/V1/M_CategoryData/CardInformationByCase",
    GetLiteratureByQuestionList: wansi_url + "/Api/V1/GetCategoryDataController/GetLiteratureByQuestionList", //问题优先类别选择
    GetQusetionTypeList: wansi_url + "/Api/V1/M_QusetionType/GetQusetionTypeList", //问题优先类别选择
    GetUserCategory: wansi_url + "/Api/V1/Table_PbiLink/GetUserCategory",
    GetRandomTopicSelection: wansi_url + "/Api/V1/M_CategoryData/GetRandomTopicSelection",
    CreateCaseByCompany: wansi_url + "/Api/V1/M_CategoryData/CreateCaseByCompany",

    GetPaperAI:wansi_url + "/Api/V1/D_AIQuestion/GetPaperAI", //论文质量检查

    GetHomeCompanyCodeList: wansi_url + "/Api/V1/GetCategoryDataController/GetHomeCompanyCodeList",
    GetQuestionWordCloudDataByNameInnovate: wansi_url + "/Api/V1/M_CategoryData/GetQuestionWordCloudDataByNameInnovate",
    GetQuestionWordCloudDataByNameInnovate: wansi_url + "/Api/V1/M_CategoryData/GetQuestionWordCloudDataByNameInnovate",
    GetMethodWordByCategoryNameInnovate: wansi_url + "/Api/V1/M_CategoryData/GetMethodWordByCategoryNameInnovate",

    // 文本检索
    GetJYMenuList: base_url + "/Api/V1/HomePage/GetJYMenuList", //选择预设


    // 教学案例
    GetKnowledgeMenu: base_url + "/Api/V1/D_VdcCaseReport/GetKnowledgeMenu", //案例分类
    GetPagesVdcCaseReport: base_url + "/Api/V1/D_VdcCaseReport/GetPagesVdcCaseReport", //案例列表
    GetVdcCaseReport: base_url + "/Api/V1/D_VdcCaseReport/GetVdcCaseReport", //详情

    CheckCaseReport: base_url + "/Api/V1/D_SchoolCaseReport/CheckCaseReport", //验证该校是否有定制案例（班级）
    GetPagesSchoolVcaseClass: base_url + "/Api/V1/D_SchoolVcaseClass/GetPagesSchoolVcaseClass", //获取学校的定制班级列表
    GetPagesSchoolVcaseClassChapter: base_url + "/Api/V1/D_SchoolVcaseClass/GetPagesSchoolVcaseClassChapter",//定制案例班级下章节列表获取
    GetPagesSchoolVcaseClassChapterVideo: base_url + "/Api/V1/D_SchoolVcaseClass/GetPagesSchoolVcaseClassChapterVideo",//定制案例 指定章节下的视频与案例列表获取
    GetSchoolCaseReport: base_url + "/Api/V1/D_SchoolCaseReport/GetSchoolCaseReport",//案例报告详情


    GetPunishData: base_url + "/Api/V1/HomePage/GetPunishData", //处罚检索、会计准则检索
    GetInquiriesData: base_url + "/Api/V1/HomePage/GetInquiriesData", //会计事项检索


    SearchAIGDataGet: base_url + "/Api/V1/M_AIQuestion/SearchAIGDataGet", //文本分析

};
var obj = {
    url,
    base_url,
    vcase_url,
    powerbi,
    aie,
    rag,
    file_load,
    write_online,
    wansi_url,
    qianfan,
    scope: {},
    post(config) {
        var p_url = url[config.url] || config.url;
        config.data = config.data || {};

        /*
            ID: "114"
            account: "001"
            email: "yk@163.com"
            msg: "登录成功"
            name: "liu"
            school: "产品支持"
            telephone: "18640195989"
        */
        if (
            config.url != "login" &&
            this.store.Header &&
            this.store.Header.Guid
        ) {
            config.data.Header = this.store.Header;
        }
        axios
            .post(p_url, config.data)
            .then((res) => {
                // if (config.file) {
                //     console.log('postfile:',res.data)
                //     var blob = new Blob([res.data], {
                //         type: {
                //             xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                //         } [config.fileType]
                //     });
                //     window.open(window.URL.createObjectURL(blob))
                //     return
                // }
                // console.log(res)
                if (res.data || res.data.toString) {
                    // console.log("res.data:",config.url,res.data)
                    if (res.data.Status) {
                        switch (res.data.Status) {
                            case -3:
                            case "-3":
                                // 您还未登陆
                            case -7:
                            case "-7": 
                                // 超时
                                localStorage.Header = "";
                                this.store.out_url =
                                    location.pathname + location.search;
                                    ElMessageBox.alert('尊敬的用户:我们检测到您的账号于 ['+ res.data.OccurDateTime +'] 进行了登录操作。这可能是一个安全风险，建议您立即登录账号后到个人设置里面进行密码修改操作。为了您的账号安全，请务必保持警惕，并定期更新您的密码。', '账号重复登录警告！', {
                                        confirmButtonText: '确定',
                                        callback: action => {
                                          if (action === 'confirm') {
                                            // console.log('用户点击了确定按钮');
                                            this.app.$router.replace({ name: "login" });
                                            // 这里可以添加关闭消息框的逻辑，但实际上 ElMessageBox.alert 的消息框在点击确认后会自动关闭
                                          }else{
                                            this.app.$router.replace({ name: "login" });
                                          }
                                        }
                                      });
                                break;
                            case -6:
                            case "-6":
                                // 权限不足
                                if (this.app.$route.matched.length > 1) {
                                    this.app.$router.back();
                                } else {
                                    this.app.$router.replace({ name: "login" });
                                }
                                break;
                        }
                        if (config.fail) {
                            config.fail(res.data);
                            return;
                        }
                        ElMessage({
                            message: "提示:" + res.data.message,
                            type: "error",
                        });
                        /*Notification.error({
                            title: '提示',
                            message: res.data.msg
                        })*/
                        return;
                    }
                    /*Notification.success({
                        title: '提示',
                        message: res.data.msg
                    })*/
                    config.success && config.success(res.data);
                    return;
                }
                ElMessage({
                    message: "接口返回为空",
                    grouping: true,
                    type: "error",
                });
            })
            .catch((error) => {
                console.log("error", [error]);
                var err_res = {
                    msg: "接口返回失败",
                };
                if (error.response) {
                    err_res.msg = {
                            "Internal Server Error": "内部服务器错误",
                        } [error.response.statusText] ||
                        (error.response.data && error.response.data.Message) ||
                        "接口返回失败";
                } else {
                    err_res.msg = "页面程序错误";
                }
                if (config.fail) {
                    config.fail({
                        // errCode: 500,
                        msg: err_res.msg,
                    });
                } else {
                    ElMessage({
                        message: err_res.msg,
                        grouping: true,
                        type: "error",
                    });
                    if (!error.response) {
                        console.error(error.stack);
                    }
                }
                config.error &&
                    config.error({
                        url: p_url,
                    });
            });
    },
    get(config) {
        var p_url = url[config.url] || config.url;
        config.data = config.data || {};
        /*
            ID: "114"
            account: "001"
            email: "yk@163.com"
            msg: "登录成功"
            name: "liu"
            school: "产品支持"
            telephone: "18640195989"
        */
        /*if(this.store.user.ID){
            config.data.school = this.store.user.school
            config.data.account = this.store.user.account
            config.data.name = this.store.user.name
        }
        let params = new URLSearchParams();
        for (var i in config.data) {
            if (config.data[i] instanceof Array) {
                for (var j = 0; j < config.data[i].length; j++) {
                    params.append(i, config.data[i][j]);
                };
            } else if (config.data[i] instanceof Object) {
                params.append(i, JSON.stringify(config.data[i]));
            } else {
                params.append(i, config.data[i]);
            }
        };
        config.data = params;*/
        // console.log("get", p_url);
        axios
            .get(p_url, config.data, {
                maxContentLength: 4000,
            })
            .then(function(res) {
                if (res.data || res.data.toString) {
                    if (res.data.errCode || res.data.Status) {
                        if (config.fail) {
                            config.fail(res.data);
                            return;
                        }
                        ElMessage({
                            message: "提示:" + res.data.message,
                            type: "error",
                        });
                        /*Notification.error({
                            title: '提示',
                            message: res.data.msg
                        })*/
                        return;
                    }
                    /*Notification.success({
                        title: '提示',
                        message: res.data.msg
                    })*/
                    config.success && config.success(res.data);
                    return;
                }
                ElMessage({
                    message: "接口返回为空",
                    grouping: true,
                    type: "error",
                });
            })
            .catch(function(error) {
                if (config.fail) {
                    config.fail({
                        errCode: 500,
                        msg: "接口返回失败",
                    });
                } else {
                    ElMessage({
                        message: "接口返回失败",
                        grouping: true,
                        type: "error",
                    });
                }
                config.error &&
                    config.error({
                        url: p_url,
                    });
            });
    },
    url_obj() {
        return Qs.parse(location.search.replace("?", ""));
    },
    url_str(obj, _h = "") {
        return _h + Qs.stringify(obj);
    },
    get_url_argument(name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = decodeURI(window.location.search).substr(1).match(reg);
        if (r != null) return unescape(r[2]);
        return null;
    },
    get_url_argument_obj(str) {
        var obj = {};
        var reg = new RegExp("([^\\?|&]*)=([^&]*)", "g");
        var r = decodeURI(str || window.location.search).match(reg);
        if(r){
            for (var i = 0; i < r.length; i++) {
                var t = r[i].split("=");
                obj[t[0]] = t[1];
            }
        }
        return obj;
    },
    url_passer(obj, key) {
        var _this_ = this;
        var returnStr = "";
        switch (typeof obj) {
            case "string":
            case "number":
            case "boolean":
                returnStr += "&" + key + "=" + obj;
                break;
            default:
                for (var i in obj) {
                    if (typeof obj[i] !== "function") {
                        if (key && key !== "?" && key !== "&") {
                            returnStr += _this_.url_passer(
                                obj[i],
                                key + "[" + i + "]"
                            );
                        } else {
                            returnStr += _this_.url_passer(obj[i], i);
                        }
                    }
                }
                break;
        }
        switch (key) {
            case "&":
                return returnStr.replace(/^./, "");
            case "?":
                return returnStr.replace(/^./, "?");
        }
        /*if (key === '?') {
            return returnStr.replace(/^./, '?')
        } else if (key === '&') {
            return returnStr.replace(/^./, '')
        }*/
        return returnStr;
    },
    column_prop_filter(item, first = [], not = []) {
        var arr = [];
        var keys = Object.keys(item);
        // console.log("column_prop_filter",keys,first,not)
        arr = first.filter((a_item) => {
            return keys.indexOf(a_item) != -1;
        });
        arr = arr.concat(
            keys.filter((a_item) => {
                return [...first, ...not].indexOf(a_item) == -1;
            })
        );
        return arr;
    },
    list_page_filter(list, page, page_size = 10) {
        var arr = [...list];
        arr = arr.slice((page - 1) * page_size, page * page_size);
        return arr;
    },
    arr_a_filter_b(a, b = []) {
        return a.filter((a_item) => {
            return b.indexOf(a_item) == -1;
        });
    },
    str_filter(val, _config, recursion) {
        _config = {
            s: 6,
            e: 4,
            ..._config,
        };
        if (!val) {
            return val;
        }
        return (
            val.substring(0, _config.s) +
            "..." +
            val.substring(val.length - _config.e)
        );
    },
    /*testFun(){
        console.log("testFun",this)
    }*/
    createDownLoad(content, fileName) {
        const link = document.createElement("a");
        link.href = content;
        const d = new Date();
        link.download =
            fileName ||
            d.getFullYear() + "-" + (d.getMonth() + 1) + "-" + d.getDate();
        link.target = "_blank";
        const ev = document.createEvent("MouseEvents");
        ev.initMouseEvent(
            "click",
            true,
            false,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null
        );
        link.dispatchEvent(ev);
    },
};
// String.prototype._ajax = obj
// String.prototype._testFun = obj.testFun

export default obj;